<template lang="pug">
	.main-wrapper.procedimentos-listar
		header.main-wrapper-header
			.p-grid.p-align-center
				.p-col-12.ta-right
					h1.text-header.text-secondary MedClub / <b>Procedimentos</b>

		Dialog.dialogApagar(header='Remover Procedimento' :visible.sync='dialogApagar' :modal='true')
			p Deseja remover a unidade <b>{{ dialogApagar_data.ds_procedimento }}</b>?
			.ta-center.my-4
				ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
				Button.p-button-danger(v-else label='Remover' @click='remove()')

		Dialog(header='Subgrupos de ' :visible.sync='dialogSubgrupos' :modal='true')
			.ta-center(style='max-width: 500px')
				DataTable(:value="subgrupos")
					//- Column(headerStyle='width: 5%' bodyStyle='text-align:center' header='Status')
					//- 	template(#body='props')
					//- 		ProgressSpinner.waitingStatus(v-if='waitingStatus === props.data.ie_valor || waitingSelecionados.find(a=> a === props.data.id)' strokeWidth='6')
					//- 		.status-indicator(v-else :class="{ off: ! props.data.ie_status }" @click='alterarStatus(props.data.ie_valor)')
					//- 			i.jam(:class="{ 'jam-check': props.data.ie_status, 'jam-minus': !props.data.ie_status }")
					Column(headerStyle='width: 4%;' field='ie_valor' header='Código')
						template(#body='props')
							span(v-tooltip.top="props.data.ie_valor" style='cursor: pointer;') {{ props.data.ie_valor }}
					Column(headerStyle='width: 15%;' field='ds_valor' header='Subgrupo')
						template(#body='props')
							span(v-tooltip.top="props.data.ds_valor" style='cursor: pointer;') {{ props.data.ds_valor }}      

		Panel.mb-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
			.p-grid.p-fluid.p-align-end

				.p-col-12.p-md-3
					label.form-label Código:
					.p-inputgroup
						InputText(
							placeholder='Código'
							:autoClear='false'
							@keyup.enter.native='applyFilters()'
							@input='checkEmptyField("cd_procedimento")'
							v-model='filters.cd_procedimento'
						)
						Button(icon='jam jam-search' @click='applyFilters()')

				.p-col-12.p-md-3
					label.form-label Nome:
					.p-inputgroup
						InputText(
							placeholder='Nome'
							:autoClear='false'
							@keyup.enter.native='applyFilters()'
							@input='checkEmptyField("nm_procedimento")'
							v-model='filters.nm_procedimento'
						)
						Button(icon='jam jam-search' @click='applyFilters()')

				.p-col-12.p-md-3
					label.form-label Descrição:
					.p-inputgroup
						InputText(
							placeholder='Descrição'
							:autoClear='false'
							@keyup.enter.native='applyFilters()'
							@input='checkEmptyField("ds_procedimento")'
							v-model='filters.ds_procedimento'
						)
						Button(icon='jam jam-search' @click='applyFilters()')

				.p-col-12.p-md-3
					label.form-label Grupo:
					.p-inputgroup
						MultiSelect(v-model='filters.cd_grupo_procedimento' :options='options.grupo_procedimentos'
							optionLabel='ds_descricao' optionValue='id' placeholder='Selecione' @change='applyFilters()' :filter='Boolean(options.grupo_procedimentos.length)')
				.p-col-12.p-md-3
					label.form-label Subgrupos:
					.p-inputgroup
						MultiSelect(
							:filter='true'
							:options='options.subgrupos'
							placeholder='TODOS'
							optionLabel='text'
							optionValue='value'
							dataKey='value'
							v-model='filters.cd_subgrupos'
							@change='applyFilters()'
						)
				.p-col-12.p-md-3
					label.form-label Status:
					Dropdown(
						v-model='filters.ie_status'
						:options='options.ieStatus'
						optionLabel='text'
						optionValue='value'
						dataKey='value'
						placeholder='TODOS'
						@change='applyFilters()'
					)
				
				.p-col-12.p-md-3
					label.form-label Tipo:
					Dropdown(v-model='filters.cd_tipo_procedimento' :options='options.cd_tipo_procedimentos' @change='applyFilters()'
						optionLabel='ie_tipo_procedimento' optionValue='id' placeholder='Selecione')
		
				.p-col-12.p-md-3
					Button(label='Limpar Filtros' icon='jam jam-rubber' @click='clearFilters()')
		.ta-right.mt-4.mb-2
			Button(label='Adicionar' icon="jam jam-plus" @click="$router.push('/procedimentos/salvar/0/')")

		ProgressBar(v-if='waiting' mode="indeterminate")
		div(v-else-if='list.length == 0')
			p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
		div(v-else)
			DataView.dataview.my-2(:value="list" layout="grid")
				template(#grid="props")
					.p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
						Panel.panel-list.ta-center(:header="props.data.cd_procedimento" style='position: relative')
							.ta-left
								p <b>Código:</b> {{ props.data.cd_procedimento }}
								p <b>Nome do Procedimento:</b> {{ props.data.nm_procedimento }}
								p <b>Descrição:</b> {{ props.data.ds_procedimento }}

			Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

			Panel.datatable(header='Lista de Procedimentos')
				Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
				DataTable(:value="list" @sort="onSort($event)" :sortField="order.field" :sortOrder="order.sortOrder")
					Column(headerStyle='width: 5%;' bodyStyle='text-align: center;')
						template(#body='props')
							ProgressSpinner.waitingStatus(v-if='waitingStatus === props.data.id' strokeWidth='6')
							.status-indicator(v-else :class="{ off: ! props.data.ie_status }" @click='alterarStatus(props.data.id)')
								i.jam(:class="{ 'jam-check': props.data.ie_status, 'jam-minus': !props.data.ie_status }")
					Column(headerStyle='width: 8%;' bodyStyle='text-align: center;' field='cd_procedimento' header='Código' sortable)
					Column(headerStyle='width: 8%' bodyStyle='text-align: center;' field='ds_sigla' header='Sigla' sortable)
					Column(headerStyle='width: 25%;' field='nm_procedimento' header='Nome do procedimento' sortable)
						template(#body='props')
							p(style='margin: 2px 0') <b>{{ props.data.nm_procedimento }} </b>
							p(style='margin: 2px 0') {{ props.data.ds_procedimento }}
					Column(headerStyle='width: 25%' header='Sinônimos' field="ds_sinonimia")
						template(#body='props')
							p(style='margin:2px 0') {{ props.data.ds_sinonimia }}
					Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' header="Tipo" field="ds_tipo_procedimento")
					Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' header="Grupo" field="ds_grupo_procedimento")
					Column(headerStyle='width: 15%; text-align: center' header="Ações")
						template(#body='props')
							.ta-center
								Button.p-button-raised.p-button-rounded.mr-1(
									v-tooltip.top="'Editar'"
									icon="jam jam-write"
									@click="$router.push(`/procedimentos/salvar/${ props.data.id }/`)"
								)
								Button.p-button-raised.p-button-rounded.p-button-danger.mr-1(
									v-tooltip.top="'Apagar'"
									icon="jam jam-minus-circle"
									@click="dialogApagar = true; dialogApagar_data = props.data"
								)
								Button.p-button-raised.p-button-rounded.p-button-secondary(
									v-if='props.data.cd_subgrupos.length'
									style='background-color: #9B0079; color: white'
									v-tooltip.top="'Subgrupos'"
									icon="jam jam-merge"
									@click='openDialogSubgrupos(props.data.cd_subgrupos)'
								)
				Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

</template>

<style lang="scss">
	.procedimentos-listar {
		.dataview {
			@media all and (min-width: 577px) {
				display: none;
			}
		}
		.datatable {
			@media all and (max-width: 576px) {
				display: none;
			}
			.cell {
				padding: 16px 0;
				text-align: center;
				&.ex  {  background-color: #e4f8e1; }
				&.a  { background-color: #faf3dd; }
			}
		}
		.dialogApagar {
			.p-progress-spinner {
				width: 29px;
				height: auto;
			}
		}

		.p-progress-spinner{max-width:100%}

		.status-indicator {
			display: inline-block;
			font-size: 0;
			width: 23px;
			height: 23px;
			line-height: 36px;
			border-radius: 50%;
			background-color: #94c860;
			border: 1px solid #84b553;
			transition: 250ms;
			cursor: pointer;
			padding-right:10px;
			&:hover { background-color: #84b553; }
			&.off {
				background-color: #d33838;
				border: 1px solid #b33030;
				&:hover { background-color: #b33030; }
			}
			.jam {
				color: #fff;
				margin-left:2px;
			}
		}
		.p-multiselect-filter-container{
			width: 85% !important;
		}
		.p-multiselect-filter.p-component.p-inputtext.p-component {
			width: 100%;
		}
	}
	.p-tooltip {
		max-width: none;
	}
	
</style>

<script>
	import ProgressBar from 'primevue/progressbar'
	import DataView from 'primevue/dataview'
	import Panel from 'primevue/panel'
	import Paginator from 'primevue/paginator'
	import DataTable from 'primevue/datatable'
	import Column from 'primevue/column'
	import Button from 'primevue/button'
	import Tooltip from 'primevue/tooltip'
	import Dialog from 'primevue/dialog'
	import InputText from 'primevue/inputtext'
	import ProgressSpinner from 'primevue/progressspinner'
	import Dropdown from 'primevue/dropdown'
	import MultiSelect from 'primevue/multiselect'
	import { Procedimentos, DominioValor } from './../../middleware'
	import wsConfigs from './../../middleware/configs'
	import { processDominiosResponse } from "@/utils";

	export default {
		beforeRouteEnter (to, from, next) {
			next(vm => {
				let routeName = vm.$route.path.split('/')[1]
				if ((from.path.split('/')[1] === routeName || from.path.split('/')[1] === 'procedimentos') && vm.$store.state.filters)
					vm.filters = vm.$store.state.filters
				vm.$store.commit('clearFilters')
				vm.applyFilters()
			})
		},
		beforeRouteLeave (to, from, next) {
			let routeName = this.$route.path.split('/')[1]
			if (to.path.split('/')[1] === routeName)
				if (this.filters) this.$store.commit('setFilters', this.filters)
			next()
		},
		created () { 
			this.getDominios()
			this.getTipoProcedimento()
			Procedimentos.getGrupoProcedimentos().then(response => {
				if (response.status == 200) {
					this.options.grupo_procedimentos = response.data
				}
			}).then(() => this.applyFilters())
		},
		components: { ProgressBar, DataView, Panel, Paginator, DataTable, Dropdown,
			Column, Button, Tooltip, Dialog, ProgressSpinner, InputText, MultiSelect },
		directives: { tooltip: Tooltip },
		data () {
			return {
				list: [],
				subgrupos: [],
				windowInnerWidth: window.innerWidth,
				waiting: false,
				waitingStatus:false,
				waitingApagar: false,
				dialogApagar: false,
				dialogApagar_data: {},
				dialogSubgrupos: false,
				filters: {
					cd_subgrupos: [],
					cd_procedimento: '',
					nm_procedimento: '',
					ds_procedimento: '',
					cd_grupo_procedimento: null,
					ie_status: 'true',
					cd_tipo_procedimentos: null
				},
				options: {
					cd_subgrupos: [],
					grupo_procedimentos: [],
					cd_tipo_procedimentos:[],
					ieStatus: [
						{ text: "TODOS", value: null },
						{ text: "Ativo", value: 'true' },
						{ text: "Inativo", value: 'false' },
					]
				},
				paginator: {
					page: this.$route.query.pg ? this.$route.query.pg : 1,
					per_page: wsConfigs.paginator_perPage,
					count: 0
				},
				order: {
					field: 'cd_procedimento',
					sortOrder: 1
				}
			}
		},
		methods: {
			getList (params) {
				this.waiting = true
				return Procedimentos.findAll(params).then(response => {
					if (response.status == 200) {
						this.paginator.count = response.data.count
						this.list = response.data.results
					}
					this.waiting = false
					return true
				})
			},
			getTipoProcedimento(){
				Procedimentos.getTipoProcedimentos().then(response => {
					if (response.status == 200) {
						this.options.cd_tipo_procedimentos = response.data
					}
				})
			},
			formatPrice (val) {
				return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
			},
			clearFilters() {
				this.filters.cd_procedimento = '';
				this.filters.nm_procedimento = '';
				this.filters.ds_procedimento = '';
				this.filters.cd_grupo_procedimento = null;
				this.filters.cd_tipo_procedimento = null;
				this.applyFilters();
			},
			applyFilters () {
				let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
				params.order = `${ this.order.sortOrder === -1 ? '-' : '' }${ this.order.field }`
				if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
				Object.keys(this.filters).forEach((key) => { if (this.filters[key]) params[key] = this.filters[key]  })
				this.getList(params)
			},
			onPage (ev) {
				this.paginator.page = ev.page + 1
				this.applyFilters()
			},
			onSort(ev) {
				this.order.field = ev.sortField
				this.order.sortOrder = ev.sortOrder
				this.applyFilters()
			},
			checkEmptyField (field) {
				if (! this.filters[field].length) this.applyFilters()
			},
			openDialogSubgrupos(subgrupos) {
				this.subgrupos = subgrupos
				this.dialogSubgrupos = true
			},
			getDominios() {
				DominioValor.findAll({ds_mnemonico: ['SUBGRUPOS']}).then(response => {
					this.dominios = processDominiosResponse(response)
					if (response.status === 200) {
						this.options.subgrupos = response.data['SUBGRUPOS'].valores.map(subgrupo => ({
							value: subgrupo.ie_valor,
							text: subgrupo.ds_valor ,
							ds_subgrupo: subgrupo.ds_valor,
							id: subgrupo.ie_valor
						}))
					}
				});
			},
			alterarStatus (id) {
				let procedimento = this.list.find(a => a.id === id)
				this.waitingStatus = id
				Procedimentos.save({ id, ie_status: ! procedimento.ie_status }).then(response => {
					this.waitingStatus = 0
					if (response.status === 201) {
						procedimento.ie_status = ! procedimento.ie_status
						if (procedimento.ie_status)
							this.$toast.success('Procedimento habilitado', { duration: 3000 })
						else
							this.$toast.info('Procedimento desabilitado', { duration: 3000 })
					} else if (response.status === 400) {
						this.$toast.error(response.data.detail, { duration: 3000 })
					}
				})
			},
			remove () {
				this.waitingApagar = true
				Procedimentos.remove(this.dialogApagar_data.id).then(response => {
					this.waitingApagar = false
					if (([200, 201, 204]).includes(response.status)) {
						this.$toast.info('Procedimento removido com sucesso', { duration: 3000 })
						this.dialogApagar = false
						this.applyFilters()
					} else if (response.data.detail) {
						if (typeof response.data.detail == 'string')
							this.$toast.error(response.data.detail, { duration: 3000 })
						else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
					}
				})
			}
		}
	}
</script>
